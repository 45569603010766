import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Product from '../components/Product';
import { getShopifyLocaleFields, getProductCards, slugify } from '../utils';

const ProductPageTemplate = ({
  data: {
    datoCmsProduct,
    shopifyProduct,
    allShopifyProductVariant,
    allShopifySampleProductVariant,
    datoCmsProductSitewide,
    mainCategory,
    relatedProductsDatoCms,
    relatedProductsShopify,
    categoryProductsDatoCms,
    categoryProductsShopify,
    bestSellingProductsDatoCms,
    bestSellingProductsShopify,
    datoCmsCroppingTool,
    datoCmsSite: {
      name,
      globalSeo: { titleSuffix },
    },
  },
  location,
}) => {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);

  const { locale, slugLocales } = datoCmsProduct;

  const { seo, title } = getShopifyLocaleFields(shopifyProduct, locale);

  const relatedProducts = getProductCards(
    relatedProductsDatoCms.nodes,
    relatedProductsShopify.nodes,
    locale
  );

  const categoryProducts = getProductCards(
    categoryProductsDatoCms.nodes,
    categoryProductsShopify.nodes,
    locale
  );

  const bestSellingProducts = getProductCards(
    bestSellingProductsDatoCms.nodes,
    bestSellingProductsShopify.nodes,
    locale
  );

  const recentlyViewedProductsLocalStorageKey = `${slugify(
    name
  )}:recently-viewed-products`;

  useEffect(() => {
    const currentRecentlyViewedSearches =
      JSON.parse(localStorage.getItem(recentlyViewedProductsLocalStorageKey)) ||
      [];

    setRecentlyViewedProducts(currentRecentlyViewedSearches);

    const recentlyViewed = [...currentRecentlyViewedSearches];

    // Set product to recently reviewed
    // If the product already exists then remove and add back so it's first
    if (
      recentlyViewed.filter(({ slug }) => datoCmsProduct.slug === slug).length >
      0
    ) {
      const index = recentlyViewed
        .map(({ slug }) => slug)
        .indexOf(datoCmsProduct.slug);

      recentlyViewed.splice(index, 1);

      recentlyViewed.unshift({
        ...datoCmsProduct,
        ...shopifyProduct,
      });

      localStorage.setItem(
        recentlyViewedProductsLocalStorageKey,
        JSON.stringify(recentlyViewed)
      );
    } else {
      // Add product to the list (data for the card)
      recentlyViewed.unshift({
        ...datoCmsProduct,
        ...shopifyProduct,
      });

      // If recentlyViewed length > 15 then reduce it
      recentlyViewed.length > 15 && recentlyViewed.slice(0, 15);

      localStorage.setItem(
        recentlyViewedProductsLocalStorageKey,
        JSON.stringify(recentlyViewed)
      );
    }
  }, []);

  return (
    <Layout locale={locale} slugLocales={slugLocales} page={datoCmsProduct}>
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
      </HelmetDatoCms>
      <main>
        <Product
          shopifyProduct={shopifyProduct}
          allShopifyProductVariant={allShopifyProductVariant}
          allShopifySampleProductVariant={allShopifySampleProductVariant}
          datoCmsProduct={datoCmsProduct}
          datoCmsProductSitewide={datoCmsProductSitewide}
          mainCategory={mainCategory}
          relatedProducts={relatedProducts}
          categoryProducts={categoryProducts}
          bestSellingProducts={bestSellingProducts}
          recentlyViewedProducts={recentlyViewedProducts}
          location={location}
          datoCmsCroppingTool={datoCmsCroppingTool}
          version={2}
        />
      </main>
    </Layout>
  );
};

export const ProductDetailPageTemplateQuery = graphql`
  query ProductDetailPageTemplateQuery(
    $id: String!
    $locale: String!
    $shopifyProduct: String!
    $productSitewideId: String!
    $mainCategoryHandle: String
    $relatedProducts: [String]
    $categoryProducts: [String]
    $bestSellingProducts: [String]
  ) {
    datoCmsProduct(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      mainImages: images {
        ...DatoCmsProductGalleryMainImageFragment
      }
      thumbnailImages: images {
        ...DatoCmsProductGalleryThumbnailImageFragment
      }
      cardImageOne: productCardImageOne {
        gatsbyImageData(width: 490, height: 600)
        alt
      }
      reviews {
        author
        text
        score
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
      }
      relatedProductsHeading
      ...LinkFragment
    }
    shopifyProduct(handle: { eq: $shopifyProduct }) {
      id
      seo {
        title
        description
      }
      title
      options {
        name
        values
      }
      descriptionHtml
      descriptiveName: metafield(
        key: "descriptive_name"
        namespace: "my_fields"
      ) {
        value
      }
      patternMatchMetafield: metafield(
        key: "pattern_match"
        namespace: "my_fields"
      ) {
        patternMatch: value
      }
      verticalPatternRepeatMetafield: metafield(
        key: "vertical_pattern_repeat"
        namespace: "my_fields"
      ) {
        verticalPatternRepeat: value
      }
      wideCropMetafield: metafield(
        key: "enable_wide_crop"
        namespace: "custom"
      ) {
        wideCrop: value
      }
      wideCropMaxMetafield: metafield(
        key: "wide_crop_max_images"
        namespace: "custom"
      ) {
        wideCropMax: value
      }
      translations {
        fr {
          seo {
            title
            description
          }
          title
          descriptionHtml
          descriptiveName {
            value
          }
          options {
            name
            values
            translations {
              name
              values
            }
          }
        }
      }
    }
    allShopifyProductVariant: allShopifyProductVariant(
      filter: {
        product: { handle: { eq: $shopifyProduct } }
        selectedOptions: {
          elemMatch: {
            name: { eq: "Product Type" }
            value: { eq: "Wallpaper" }
          }
        }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        storefrontId
        sku
        presentmentPrices {
          price {
            amount
            currencyCode
          }
        }
        selectedOptions {
          name
          value
        }
        metafields {
          id
          key
          value
        }
        product {
          storefrontId
          handle
        }
        mainImage: image {
          ...ShopifyProductGalleryMainImageFragment
        }
        thumbnailImage: image {
          ...ShopifyProductGalleryThumbnailImageFragment
        }
      }
    }
    allShopifySampleProductVariant: allShopifyProductVariant(
      filter: {
        product: { handle: { eq: $shopifyProduct } }
        selectedOptions: {
          elemMatch: { name: { eq: "Product Type" }, value: { eq: "Sample" } }
        }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        storefrontId
        sku
        price
        presentmentPrices {
          price {
            amount
            currencyCode
          }
        }
        selectedOptions {
          name
          value
        }
        metafields {
          id
          key
          value
        }
        product {
          storefrontId
          handle
        }
      }
    }
    datoCmsProductSitewide(id: { eq: $productSitewideId }) {
      popularCategories {
        id
        title
        ...LinkFragment
      }
      paperTypes {
        name
        image {
          gatsbyImageData(width: 345, height: 250)
          alt
        }
        shortText
        longText
      }
      iconPoints {
        icon {
          url
          alt
        }
        text
      }
      excessMessaging
      rollWidth
      rollHeight
      fireRatings
      installMethod
      paperType
      eco
      accordionItems {
        id
        heading
        content
      }
      modularBlocksOneSitewide: modularBlocksOne {
        ...FeaturedCustomerPhotosModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...TestimonialsModularBlockFragment
      }
      modularBlocksTwoSitewide: modularBlocksTwo {
        ...FeaturedCustomerPhotosModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...TestimonialsModularBlockFragment
      }
      paperSamples {
        ...ImageContentModularBlockV2Fragment
      }
      zoomTooltipMessaging
    }
    mainCategory: datoCmsProductCategory(
      locale: { eq: $locale }
      shopifyCollection: { eq: $mainCategoryHandle }
    ) {
      title
      ...LinkFragment
    }
    relatedProductsDatoCms: allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        shopifyProduct: { in: $relatedProducts }
      }
    ) {
      nodes {
        ...DatoCmsProductCardFragment
      }
    }
    relatedProductsShopify: allShopifyProduct(
      filter: { handle: { in: $relatedProducts } }
    ) {
      nodes {
        ...ShopifyProductCardFragment
      }
    }
    categoryProductsDatoCms: allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        shopifyProduct: { in: $categoryProducts }
      }
    ) {
      nodes {
        ...DatoCmsProductCardFragment
      }
    }
    categoryProductsShopify: allShopifyProduct(
      filter: { handle: { in: $categoryProducts } }
    ) {
      nodes {
        ...ShopifyProductCardFragment
      }
    }
    bestSellingProductsDatoCms: allDatoCmsProduct(
      filter: {
        locale: { eq: $locale }
        shopifyProduct: { in: $bestSellingProducts }
      }
    ) {
      nodes {
        ...DatoCmsProductCardFragment
      }
    }
    bestSellingProductsShopify: allShopifyProduct(
      filter: { handle: { in: $bestSellingProducts } }
    ) {
      nodes {
        ...ShopifyProductCardFragment
      }
    }
    datoCmsCroppingTool(locale: { eq: $locale }) {
      contentBlock1
      contentBlock2
      contentBlockStandardCrop
      contentBlockWideCrop
    }
    datoCmsSite {
      name
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductPageTemplate;
